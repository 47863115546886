import './ObtenerOrdenes.css'
import React, { useEffect, useState, useContext } from 'react'
import { getDocs, collection, getFirestore, updateDoc, deleteDoc, doc } from 'firebase/firestore'
import { AdminContext } from '../../Contextos/AdminContext'
import swal from 'sweetalert'
import OrdenCard from '../OrdenCard/OrdenCard'

const ObtenerOrdenes = () => {

    const db = getFirestore()
    const ordenesCollection = collection(db, 'ordenes')
    const { logged } = useContext(AdminContext)
    const [ordenes, setOrdenes] = useState([])
    const [idOrdenBuscada, setIdOrdenBuscada] = useState()
    const [ordenBuscada, setOrdenBuscada] = useState([])
    const [busquedaNormalizada, setBusquedaNormalizada] = useState()

    useEffect(() => {
        if (logged) {
            getDocs(ordenesCollection)
                .then((snapshot) => {
                    const arrayProds = snapshot.docs.map((prod) => ({
                        id: prod.id,
                        ...prod.data()
                    }))
                    setOrdenes(arrayProds)
                })
                .catch((error) => {throw error})
        }
    }, [logged, ordenesCollection])

    const marcarOrden = (ev) => {
        swal({
            text: "Segura que desea modificar el estado de la orden?",
            buttons: ["No", "Si"]
        }).then((value) => {
            if (value) {
                ordenes.forEach((orden) => {
                    if (orden.id === ev) {
                        const prod = doc(ordenesCollection, `${ev}`)
                        if (orden.ordenEntregada === false) {
                            updateDoc(prod, {
                                ordenEntregada: true,
                                estadoDePago: "Confirmado"
                            })
                        } else {
                            updateDoc(prod, {
                                ordenEntregada: false
                            })
                        }
                    }
                })
            }
        })
    }

    const buscarOrden = (ev) => {
        setIdOrdenBuscada(ev.target.value)
    }

    const deleteOrden = (ordenId) => {
        deleteDoc(doc(ordenesCollection, `${ordenId}`))
    }

    useEffect(() => {
        if (idOrdenBuscada !== undefined) {
            let normalizar = idOrdenBuscada.toString()
            normalizar = normalizar.toLowerCase()
            setBusquedaNormalizada(normalizar)
        }
    }, [idOrdenBuscada]);

    useEffect(() => {
        setOrdenBuscada(ordenes.filter((orden) => orden.idPago === busquedaNormalizada || orden.idOrden === busquedaNormalizada || orden.usuario.nombre === busquedaNormalizada || orden.usuario.telefono === busquedaNormalizada))
        // eslint-disable-next-line
    }, [busquedaNormalizada])

    return (
        <div id='ordenesContainer'>
            <h1>Ordenes</h1>
            {logged ? (
                <div>
                    <div className='buscadorDeOrdenes'>
                        <h3>Buscar orden</h3>
                        <input className='formInputs' type='text' placeholder='ID Orden o ID Pago o Nombre usuario o Telefono usuario' onChange={buscarOrden} />
                    </div>
                    <div>
                        {ordenBuscada.length !== 0 ? (
                            <div className='historialOrdenesContainer'>
                                {ordenBuscada.map((orden) => (
                                    <OrdenCard isLogged={logged} orden={orden} marcarOrden={marcarOrden} deleteOrden={deleteOrden} key={ordenBuscada[0].id} />
                                ))}
                            </div>
                        ) : (
                            <div className='historialOrdenesContainer'>
                                {ordenes.map((orden) => (
                                    <OrdenCard isLogged={logged} orden={orden} marcarOrden={marcarOrden} deleteOrden={deleteOrden} key={orden.id} />
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <p>Tiene que iniciar sesion para acceder a esta area!!</p>
            )}
        </div>
    )
}

export default ObtenerOrdenes